import { TerminalVehicleEnergyConsumption } from "types/terminal-vehicle-energy-consumption";
import { TerminalVehicleStatusDatapoint } from "types/terminal-vehicle-status-datapoint";
import { downloadFile } from "utils/file";
import { convertToDateTimeFormat, generateTimeLabels } from "utils/time";

const chartLabels = generateTimeLabels();

const CHART_SCHEDULE_DOWNLOAD_HEADERS = [
  "Configuration Name",
  ...[chartLabels],
];

export const downloadChartSchedule = (
  powerProfile: number[],
  energyProfile: number[] = [],
  vehicleStatusDatapoints: TerminalVehicleStatusDatapoint[],
  vehicleEnergyConsumption: TerminalVehicleEnergyConsumption[],
  scenarioName: string | undefined
) => {
  let csvData = "";
  const appendData = (data: string = "") => {
    csvData += data;
    csvData += "\n";
  };

  appendData(CHART_SCHEDULE_DOWNLOAD_HEADERS.join(","));
  const powerData = ["Power Demand Profile (kW)", ...powerProfile.slice(0, 48)];
  const energyData = [
    "Energy Consumption Profile (kWh)",
    ...energyProfile.slice(0, 48),
  ];

  appendData(powerData.join(","));
  appendData(energyData.join(","));

  vehicleStatusDatapoints.forEach((datapoint, index) => {
    const vehicleDataString = `Vehicle ${
      index + 1
    } Charging Schedule,${datapoint.statusDatapoints.join(",")}`;
    appendData(vehicleDataString);
  });

  vehicleEnergyConsumption.forEach((energy, index) => {
    const vehicleDataString = `Vehicle ${
      index + 1
    } Energy Consumption (kWh),${energy.energyConsumption.join(",")}`;
    appendData(vehicleDataString);
  });

  downloadFile(
    csvData,
    `${scenarioName}_ChargeSchedule_${convertToDateTimeFormat(new Date())}.csv`
  );
};

const generate24HourEnergyProfile = (energy: number[]): number[] | string[] => {
  if (energy.length === 0) {
    return Array.from({ length: 24 }, (_, i) => "N/A");
  }
  return Array.from(
    { length: 24 },
    (_, i) => Number(energy[i * 2]) + Number(energy[i * 2 + 1])
  );
};

const generate24HourPowerProfile = (profile: number[]): number[] | string[] => {
  if (profile.length === 0) {
    return Array.from({ length: 24 }, (_, i) => "N/A");
  }
  return Array.from({ length: 24 }, (_, i) =>
    Math.max(Number(profile[i * 2]), Number(profile[i * 2 + 1]))
  );
};
const generate24Hours = (): string[] => {
  const hours = Array.from({ length: 24 }, (_, i) => {
    const hour = i % 12 === 0 ? 12 : i % 12;
    const period = i < 12 ? "AM" : "PM";
    return `${hour} ${period}`;
  });
  return hours;
};

const generateDailyChargingData = (
  powerProfile24Hour: number[] | string[],
  energyProfile24Hour: number[] | string[]
): string[] => {
  const energy24HourLabels = generate24Hours();

  return powerProfile24Hour.map(
    (value, i) => `${energy24HourLabels[i]},${value},${energyProfile24Hour[i]}`
  );
};

const generateYearlyChargingData = (dailyChargingData: string[]): string[] => {
  const currentYear = new Date().getFullYear();
  const startDate = new Date(currentYear, 0, 1);
  const endDate = new Date(currentYear + 1, 0, 1);
  const yearlyData: string[] = [];

  for (let date = startDate; date < endDate; date.setDate(date.getDate() + 1)) {
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "numeric",
      day: "numeric",
      year: "numeric",
    });
    dailyChargingData.forEach((data) => {
      yearlyData.push(`${formattedDate}, ${data}`);
    });
  }

  return yearlyData;
};

export const downloadEnergyProfile = (
  powerProfile: number[],
  energyProfile: number[] = [],
  evPowerProfile: number[] = [],
  evEnergyProfile: number[] = [],
  scenarioName: string | undefined,
  comparisonData?: {
    name: string | undefined;
    powerProfile: number[] | undefined;
    energyProfile: number[] | undefined;
  }
) => {
  let csvData = "";
  const appendData = (data: string = "") => {
    csvData += data;
    csvData += "\n";
  };
  const header = [
    "Hour",
    "Power Demand Profile (kW)",
    "Energy Consumption Profile (kWh)",
  ];

  if (evPowerProfile && evEnergyProfile) {
    header.push(
      ...[
        "EV Reference Power Demand (kW)",
        "EV Reference Energy Consumption (kWh)",
      ]
    );
  }

  if (comparisonData?.energyProfile && comparisonData.powerProfile) {
    header.push(
      ...[
        `${comparisonData.name} Power Demand (kW)`,
        `${comparisonData.name}  Energy Consumption (kWh)`,
      ]
    );
  }

  appendData(header.join(","));

  const energyProfile24Hour = generate24HourEnergyProfile(energyProfile);
  const powerProfile24Hour = generate24HourPowerProfile(powerProfile);
  const dailyChargingData = generateDailyChargingData(
    powerProfile24Hour,
    energyProfile24Hour
  );

  if (evPowerProfile && evEnergyProfile) {
    const evEnergyProfile24Hour = generate24HourEnergyProfile(evEnergyProfile);
    const evPowerProfile24Hour = generate24HourPowerProfile(evPowerProfile);
    dailyChargingData.forEach((value, i) => {
      dailyChargingData[
        i
      ] = `${value},${evPowerProfile24Hour[i]},${evEnergyProfile24Hour[i]}`;
    });
  }

  if (comparisonData?.energyProfile && comparisonData.powerProfile) {
    const energyProfile24Hour = generate24HourEnergyProfile(
      comparisonData.energyProfile
    );
    const powerProfile24Hour = generate24HourPowerProfile(
      comparisonData.powerProfile
    );
    dailyChargingData.forEach((value, i) => {
      dailyChargingData[
        i
      ] = `${value},${powerProfile24Hour[i]},${energyProfile24Hour[i]}`;
    });
  }

  appendData(dailyChargingData.join("\n"));

  downloadFile(
    csvData,
    `${scenarioName}_EnergyProfile_${convertToDateTimeFormat(new Date())}.csv`
  );
};
