import { Box, Grid, Typography } from "@mui/material";
import { Section } from "components/Section";
import { ChargerStatus } from "types/charger-status";
import { Schedule, Chargers } from "types/hub-scenario-data";
import ChargerAssignmentChart from "./charts/ChargerAssignmentChart";
import ChargerInformationHub from "./components/ChargerInformationHub";
import { useMemo } from "react";

const generateChargerDataset = (
  schedules: Schedule[] | undefined
): ChargerStatus[][] => {
  if (!schedules?.length) {
    return [];
  }

  // For display reasons reverse the schedules so assigned chargers are at the bottom of the chart
  const reverseSchedules = [];
  for (let i = schedules.length - 1; i >= 0; i--) {
    reverseSchedules.push(schedules[i]);
  }

  const dataset: ChargerStatus[][] = [];
  reverseSchedules.forEach((item: Schedule, index: number) => {
    const values: ChargerStatus[] = item.schedule.map(
      (status: number, key: number) => ({
        id: index.toString(),
        status: status === 1 ? "Charging" : "Idle",
      })
    );

    if (values.length !== 0) {
      dataset.push(values);
    }
  });

  return dataset;
};

type ChargersHubProps = {
  carChargersData: Chargers | undefined;
  lightDutyChargersData: Chargers | undefined;
  truckChargersData: Chargers | undefined;
  trailerChargersData: Chargers | undefined;
};

const ChargersHub = ({
  carChargersData,
  lightDutyChargersData,
  truckChargersData,
  trailerChargersData,
}: ChargersHubProps) => {
  interface ChargerChartProps {
    title: string;
    util: number;
    dataset: ChargerStatus[][];
  }

  const chargerChart: ChargerChartProps[] = useMemo(() => {
    const chargerChartData: ChargerChartProps[] = [];
    const generateChargerChartData = (
      data: any,
      type: string
    ): ChargerChartProps[] => {
      if (!data) return [];

      const publicDataset = generateChargerDataset(
        data.charger_assignments.public.schedules
      );
      const publicUtil = data.charger_assignments.public.avg_util_rate;

      const subscriptionDataset = generateChargerDataset(
        data.charger_assignments.subscription.schedules
      );
      const privateUtil = data.charger_assignments.subscription.avg_util_rate;

      return [
        {
          title: `${type} Public`,
          util: publicUtil,
          dataset: publicDataset,
        },
        {
          title: `${type} Private`,
          util: privateUtil,
          dataset: subscriptionDataset,
        },
      ];
    };

    chargerChartData.push(...generateChargerChartData(carChargersData, "Car"));
    chargerChartData.push(
      ...generateChargerChartData(lightDutyChargersData, "Light Truck")
    );
    chargerChartData.push(
      ...generateChargerChartData(truckChargersData, "Straight Truck")
    );
    chargerChartData.push(
      ...generateChargerChartData(trailerChargersData, "Trailer-Truck")
    );

    return chargerChartData;
  }, [
    carChargersData,
    lightDutyChargersData,
    truckChargersData,
    trailerChargersData,
  ]);

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container rowSpacing={2} columnSpacing={2}>
        {chargerChart.map((chart) => (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
            <Section>
              <Box sx={{ marginBottom: 3 }}>
                <Typography variant="controlTitle" sx={{ fontWeight: 600 }}>
                  {`Charger assignment chart (${chart.title})`}
                </Typography>
                <Typography variant="body1" sx={{ marginTop: 1 }}>
                  {`Utilization: ${
                    typeof chart.util === "number"
                      ? `${(chart.util * 100).toFixed(2)}%`
                      : "N/A"
                  }`}
                </Typography>
              </Box>
              <ChargerAssignmentChart
                variant={"charger"}
                fleetSchedule={chart.dataset}
              />
            </Section>
          </Grid>
        ))}
        <Grid
          container
          rowSpacing={2}
          columnSpacing={2}
          sx={{ paddingLeft: 2, paddingTop: 2 }}
          className={"charger-information"}
        >
          {carChargersData && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
              <ChargerInformationHub
                title="Charger Information (Cars)"
                totalChargers={carChargersData?.num_chargers}
                utilityConstrainedFeasibleCharger={
                  carChargersData?.utility_constrained_feasible_chargers
                }
                parkingAreaConstrainedFeasibleCharger={
                  carChargersData?.parking_area_constrained_feasible_chargers
                }
                chargersNeeded={carChargersData?.num_chargers_needed}
                assignableCharger={carChargersData?.num_assignable_chargers}
                publicCharger={carChargersData?.num_public_chargers}
                subscriptionCharger={carChargersData?.num_subscription_chargers}
              />
            </Grid>
          )}
          {lightDutyChargersData && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
              <ChargerInformationHub
                title="Charger Information (Light Truck)"
                totalChargers={lightDutyChargersData?.num_chargers}
                utilityConstrainedFeasibleCharger={
                  lightDutyChargersData?.utility_constrained_feasible_chargers
                }
                parkingAreaConstrainedFeasibleCharger={
                  lightDutyChargersData?.parking_area_constrained_feasible_chargers
                }
                chargersNeeded={lightDutyChargersData?.num_chargers_needed}
                assignableCharger={
                  lightDutyChargersData?.num_assignable_chargers
                }
                publicCharger={lightDutyChargersData?.num_public_chargers}
                subscriptionCharger={
                  lightDutyChargersData?.num_subscription_chargers
                }
              />
            </Grid>
          )}
          {truckChargersData && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
              <ChargerInformationHub
                title="Charger Information (Straight Truck)"
                totalChargers={truckChargersData?.num_chargers}
                utilityConstrainedFeasibleCharger={
                  truckChargersData?.utility_constrained_feasible_chargers
                }
                parkingAreaConstrainedFeasibleCharger={
                  truckChargersData?.parking_area_constrained_feasible_chargers
                }
                chargersNeeded={truckChargersData?.num_chargers_needed}
                assignableCharger={truckChargersData?.num_assignable_chargers}
                publicCharger={truckChargersData?.num_public_chargers}
                subscriptionCharger={
                  truckChargersData?.num_subscription_chargers
                }
              />
            </Grid>
          )}
          {trailerChargersData && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
              <ChargerInformationHub
                title="Charger Information (Tractor-Trailer)"
                totalChargers={trailerChargersData?.num_chargers}
                utilityConstrainedFeasibleCharger={
                  trailerChargersData?.utility_constrained_feasible_chargers
                }
                parkingAreaConstrainedFeasibleCharger={
                  trailerChargersData?.parking_area_constrained_feasible_chargers
                }
                chargersNeeded={trailerChargersData?.num_chargers_needed}
                assignableCharger={trailerChargersData?.num_assignable_chargers}
                publicCharger={trailerChargersData?.num_public_chargers}
                subscriptionCharger={
                  trailerChargersData?.num_subscription_chargers
                }
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChargersHub;
