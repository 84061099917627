import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Section } from "components/Section";
import ChargingProfileChart from "./chart/ChargingProfileChart";
import FleetScheduleChart from "./chart/FleetScheduleChart";
import { VehicleStatus } from "types/vehicle-status";
import { TerminalVehicleStatusDatapoint } from "types/terminal-vehicle-status-datapoint";
import { useCallback, useMemo, useState } from "react";
import {
  downloadEnergyProfile,
  downloadChartSchedule,
} from "./DownloadChartSchedule";
import { TerminalScenarioVehicle } from "types/terminal-scenario-vehicle";
import HelpTooltip from "components/HelpTooltip";
import { TerminalScenario } from "types/terminal";
import { useTerminalScenarioVehicle } from "api/terminal/scenario-vehicle";

export type energyProps = {
  scenarioVehicle: TerminalScenarioVehicle | null;
  scenarios: TerminalScenario[];
  terminalId: number;
  facilityId: number;
};

const Energy = ({
  scenarioVehicle,
  scenarios,
  terminalId,
  facilityId,
}: energyProps) => {
  const [selectedCompareScenarioId, setSelectedCompareScenarioId] =
    useState<number>(0);

  const [selectedCompareScenario, setSelectedCompareScenario] =
    useState<TerminalScenario>();

  const { terminalScenarioVehicle } = useTerminalScenarioVehicle(
    terminalId,
    facilityId,
    selectedCompareScenarioId,
    selectedCompareScenario?.scenarioVehicles[0].id
  );

  let {
    powerProfile,
    energyProfile,
    evPowerProfile,
    evEnergyProfile,
  }: {
    powerProfile: number[];
    energyProfile: number[];
    evPowerProfile: number[];
    evEnergyProfile: number[];
  } = useMemo(() => {
    return {
      powerProfile: scenarioVehicle?.demandUsageKwDatapoints || [],
      energyProfile: scenarioVehicle?.energyUsageKwhDatapoints || [],
      evPowerProfile: scenarioVehicle?.referencePowerDemandKwDatapoints || [],
      evEnergyProfile: scenarioVehicle?.referenceEnergyUsageKwhDatapoints || [],
    };
  }, [
    scenarioVehicle?.demandUsageKwDatapoints,
    scenarioVehicle?.energyUsageKwhDatapoints,
    scenarioVehicle?.referencePowerDemandKwDatapoints,
    scenarioVehicle?.referenceEnergyUsageKwhDatapoints,
  ]);

  let vehicleScheduleString: VehicleStatus[][] = useMemo(() => {
    if (!scenarioVehicle?.vehicleStatusDatapoints) {
      return [];
    }
    return scenarioVehicle?.vehicleStatusDatapoints?.map(
      (datapoint: TerminalVehicleStatusDatapoint, index) => {
        return datapoint.statusDatapoints.map((status, i) => {
          return {
            id: `${index + 1}`,
            status: status.toString(),
          };
        });
      }
    );
  }, [scenarioVehicle?.vehicleStatusDatapoints]);

  const handleEnergyProfileCSV = useCallback(() => {
    if (powerProfile && energyProfile) {
      downloadEnergyProfile(
        powerProfile,
        energyProfile,
        evPowerProfile,
        evEnergyProfile,
        scenarioVehicle?.scenario.name,
        {
          name:
            terminalScenarioVehicle?.scenario.id ===
            scenarioVehicle?.scenario.id
              ? undefined
              : terminalScenarioVehicle?.scenario.name,
          powerProfile:
            terminalScenarioVehicle?.scenario.id ===
            scenarioVehicle?.scenario.id
              ? undefined
              : terminalScenarioVehicle?.demandUsageKwDatapoints,
          energyProfile:
            terminalScenarioVehicle?.scenario.id ===
            scenarioVehicle?.scenario.id
              ? undefined
              : terminalScenarioVehicle?.energyUsageKwhDatapoints,
        }
      );
    }
  }, [
    powerProfile,
    energyProfile,
    evPowerProfile,
    evEnergyProfile,
    terminalScenarioVehicle,
    scenarioVehicle,
  ]);

  const handleChargingScheduleCSV = useCallback(() => {
    if (
      powerProfile &&
      energyProfile &&
      scenarioVehicle?.vehicleStatusDatapoints &&
      scenarioVehicle?.vehicleEnergyConsumption
    ) {
      downloadChartSchedule(
        powerProfile,
        energyProfile,
        scenarioVehicle.vehicleStatusDatapoints,
        scenarioVehicle.vehicleEnergyConsumption,
        scenarioVehicle.scenario.name
      );
    }
  }, [
    energyProfile,
    powerProfile,
    scenarioVehicle?.vehicleEnergyConsumption,
    scenarioVehicle?.vehicleStatusDatapoints,
  ]);

  return (
    <Box sx={{ padding: 2 }}>
      <Stack direction="row" justifyContent="space-between">
        <Typography
          variant="controlTitle"
          sx={{ marginTop: 1, fontWeight: 600 }}
        >
          Energy Profile
        </Typography>
        <FormControl>
          <InputLabel>Select a comparative config</InputLabel>
          <Select
            sx={{ width: "400px" }}
            labelId="scenario-select-label"
            id="scenario-select"
            label="Select a comparative config"
            title="List of Scenarios"
            onChange={(event) => {
              const selectedId = Number(event.target.value);
              const selectedScenario = scenarios.find(
                (sc) => sc.id === selectedId
              );
              if (selectedScenario)
                setSelectedCompareScenario(selectedScenario);
              setSelectedCompareScenarioId(selectedId);
            }}
          >
            <MenuItem
              key={scenarioVehicle?.scenario.id}
              value={scenarioVehicle?.scenario.id}
            >{`EV Reference Case for ${scenarioVehicle?.scenario.name}`}</MenuItem>
            {scenarios.map((scenario) => {
              if (scenario.id !== scenarioVehicle?.scenario.id) {
                return (
                  <MenuItem key={scenario.id} value={scenario.id}>
                    {scenario.name}
                  </MenuItem>
                );
              }
              return <></>;
            })}
          </Select>
        </FormControl>
      </Stack>
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
          <Section>
            <Box sx={{ marginBottom: 3 }}>
              <Typography variant="controlTitle" sx={{ fontWeight: 500 }}>
                Power Demand
              </Typography>
            </Box>
            <ChargingProfileChart
              page="terminals"
              variant="power"
              name={scenarioVehicle?.scenario.name}
              data={powerProfile}
              comparisonData={{
                name:
                  selectedCompareScenario?.id === scenarioVehicle?.scenario.id
                    ? `EV Reference Case for ${selectedCompareScenario?.name}`
                    : selectedCompareScenario?.name,
                data:
                  selectedCompareScenario?.id === scenarioVehicle?.scenario.id
                    ? scenarioVehicle?.referencePowerDemandKwDatapoints
                    : terminalScenarioVehicle?.demandUsageKwDatapoints,
              }}
            />
          </Section>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
          <Section>
            <Box sx={{ marginBottom: 3 }}>
              <Typography variant="controlTitle" sx={{ fontWeight: 500 }}>
                Energy Consumption
              </Typography>
            </Box>
            <ChargingProfileChart
              page="terminals"
              variant="energy"
              name={scenarioVehicle?.scenario.name}
              data={energyProfile}
              comparisonData={{
                name:
                  selectedCompareScenario?.id === scenarioVehicle?.scenario.id
                    ? `EV Reference Case for ${selectedCompareScenario?.name}`
                    : selectedCompareScenario?.name,
                data:
                  selectedCompareScenario?.id === scenarioVehicle?.scenario.id
                    ? scenarioVehicle?.referenceEnergyUsageKwhDatapoints
                    : terminalScenarioVehicle?.energyUsageKwhDatapoints,
              }}
            />
          </Section>
        </Grid>

        <Stack width="100%" mt={2} ml={2} alignItems={"center"}>
          <Button
            variant="contained"
            sx={{ width: "22vw" }}
            onClick={handleEnergyProfileCSV}
            className="download-button"
            disabled={!powerProfile.length || !energyProfile.length}
          >
            Download Energy Profile
          </Button>
        </Stack>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Section>
            <Box sx={{ marginBottom: 3 }}>
              <HelpTooltip
                title={
                  'The light blue "Charging" color indicates that up to two vehicles are fully utilizing any one charger for a full 30 minute time slot. The green "Partial Charging" color indicates that up to two vehicles are utilizing any one charger for less than the full 30 minute time slot.  The maximum number of vehicles that can utilize a charger simultaneously is two. For detailed information on how much each vehicle is being charged per time slot, utilize the "DOWNLOAD CHARGING PROFILES AND SCHEDULE" button below.'
                }
                placement="right"
              >
                <Typography variant="controlTitle" sx={{ fontWeight: 600 }}>
                  Vehicle Usage Data
                </Typography>
              </HelpTooltip>
            </Box>
            <FleetScheduleChart
              variant="vehicle"
              fleetSchedule={vehicleScheduleString}
            />
          </Section>
        </Grid>

        <Stack width="100%" my={2} ml={2} alignItems={"center"}>
          <Button
            variant="contained"
            sx={{ width: "22vw" }}
            onClick={handleChargingScheduleCSV}
            className="download-button"
            disabled={
              !powerProfile.length ||
              !energyProfile.length ||
              !scenarioVehicle?.vehicleStatusDatapoints.length ||
              !scenarioVehicle?.vehicleEnergyConsumption.length
            }
          >
            Download charging profiles and schedule
          </Button>
        </Stack>
      </Grid>
    </Box>
  );
};

export default Energy;
