import { useEffect, useState } from "react";
import { HubSiteType } from "types/hub-site-types";
import { useAccessToken } from "utils/get-access-token";

const useSiteTypes = (vehicleType: string) => {
  const { getToken } = useAccessToken();
  const [siteTypes, setSiteTypes] = useState<HubSiteType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>();

  const fetchData = async () => {
    const apiToken = await getToken();
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/hub/site-types?vehicleType=${vehicleType}`,
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
          },
        }
      );
      const data: HubSiteType[] = await response.json();

      setSiteTypes(data);
      setLoading(false);
    } catch (error) {
      setError(error as Error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { siteTypes, loading, error };
};

export default useSiteTypes;
