import {
  Divider,
  Stack,
  Table,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

export type EvArrivalInfoProps = {
  publicCars: number | undefined;
  publicLightDuty: number | undefined;
  subscriptionCars: number | undefined;
  subscriptionLightDuty: number | undefined;
  cars: number | undefined;
  lightDuty: number | undefined;
  carsSubscriptionCaptured: number | undefined;
  lightDutySubscriptionCaptured: number | undefined;
  publicTrucks: number | undefined;
  publicTrailers: number | undefined;
  subscriptionTrucks: number | undefined;
  subscriptionTrailers: number | undefined;
  trucks: number | undefined;
  trailers: number | undefined;
  trucksSubscriptionCaptured: number | undefined;
  trailersSubscriptionCaptured: number | undefined;
};

const EvArrivalInfo = ({
  publicCars,
  publicLightDuty,
  subscriptionCars,
  subscriptionLightDuty,
  cars,
  lightDuty,
  carsSubscriptionCaptured,
  lightDutySubscriptionCaptured,
  publicTrucks,
  publicTrailers,
  subscriptionTrucks,
  subscriptionTrailers,
  trucks,
  trailers,
  trucksSubscriptionCaptured,
  trailersSubscriptionCaptured,
}: EvArrivalInfoProps) => {
  return (
    <Box
      sx={{
        border: 0.5,
        borderColor: "silver",
        padding: "16px",
        borderRadius: 5,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="controlTitle" sx={{ fontWeight: 600 }}>
          Daily EV Arrivals
        </Typography>
      </Box>
      <Box>
        <Stack spacing={2} direction={"row"} alignItems="center">
          <Divider sx={{ marginTop: 2 }} />
          <Table border={1}>
            <TableRow>
              <TableCell>Cars (public)</TableCell>
              <TableCell align="right">{publicCars?.toFixed(2) ?? 0}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cars (private)</TableCell>
              <TableCell align="right">
                {subscriptionCars?.toFixed(2) ?? 0}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cars (total private addressable market)</TableCell>
              <TableCell align="right">{cars?.toFixed(2) ?? 0}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cars (% private captured)</TableCell>
              <TableCell align="right">{`${(
                carsSubscriptionCaptured ?? 0
              ).toFixed(0)}%`}</TableCell>
            </TableRow>
          </Table>
          <Divider sx={{ marginTop: 2 }} />
          <Table border={1}>
            <TableRow>
              <TableCell>Light Trucks (public)</TableCell>
              <TableCell align="right">
                {publicLightDuty?.toFixed(2) ?? 0}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Light Trucks (private)</TableCell>
              <TableCell align="right">
                {subscriptionLightDuty?.toFixed(2) ?? 0}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Light Trucks (total private addressable market)
              </TableCell>
              <TableCell align="right">{lightDuty?.toFixed(2) ?? 0}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Light Trucks (% private captured)</TableCell>
              <TableCell align="right">{`${(
                lightDutySubscriptionCaptured ?? 0
              ).toFixed(0)}%`}</TableCell>
            </TableRow>
          </Table>
        </Stack>
        <Stack marginTop={2} spacing={2} direction={"row"} alignItems="center">
          <Divider sx={{ marginTop: 2 }} />
          <Table border={1}>
            <TableRow>
              <TableCell>Straight Trucks (public)</TableCell>
              <TableCell align="right">
                {publicTrucks?.toFixed(2) ?? 0}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Straight Trucks (private)</TableCell>
              <TableCell align="right">
                {subscriptionTrucks?.toFixed(2) ?? 0}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Straight Trucks (total private addressable market)
              </TableCell>
              <TableCell align="right">{trucks?.toFixed(2) ?? 0}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Straight Trucks (% private captured)</TableCell>
              <TableCell align="right">{`${(
                trucksSubscriptionCaptured ?? 0
              ).toFixed(0)}%`}</TableCell>
            </TableRow>
          </Table>
          <Divider sx={{ marginTop: 2 }} />
          <Table border={1}>
            <TableRow>
              <TableCell>Tractor-Trailers (public)</TableCell>
              <TableCell align="right">
                {publicTrailers?.toFixed(2) ?? 0}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Tractor-Trailers (private)</TableCell>
              <TableCell align="right">
                {subscriptionTrailers?.toFixed(2) ?? 0}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Tractor-Trailers (total private addressable market)
              </TableCell>
              <TableCell align="right">{trailers?.toFixed(2) ?? 0}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Tractor-Trailers (% private captured)</TableCell>
              <TableCell align="right">{`${(
                trailersSubscriptionCaptured ?? 0
              ).toFixed(0)}%`}</TableCell>
            </TableRow>
          </Table>
        </Stack>
      </Box>
    </Box>
  );
};

export default EvArrivalInfo;
