import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Chart } from "react-chartjs-2";

import {
  BubbleDataPoint,
  ChartData,
  ChartOptions,
  ScatterDataPoint,
} from "chart.js";
import { ArrivalsVehicleHourlyData } from "types/hub-scenario-data";
import { Colors, ColorsRGB } from "utils/colors";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export type TrafficProfileChartProps = {
  truckHourlyArrivalsData: ArrivalsVehicleHourlyData[];
  trailerHourlyArrivalsData: ArrivalsVehicleHourlyData[];
  carHourlyArrivalsData: ArrivalsVehicleHourlyData[];
  lightDutyHourlyArrivalsData: ArrivalsVehicleHourlyData[];
};

interface Dataset {
  label: string;
  data: { x: number; y: number }[];
  borderColor: string;
  backgroundColor: string;
  fill?: boolean | number;
}

const TrafficProfileChart = ({
  truckHourlyArrivalsData,
  trailerHourlyArrivalsData,
  carHourlyArrivalsData,
  lightDutyHourlyArrivalsData,
}: TrafficProfileChartProps) => {
  const chartProperties = {
    yAxisTitle: "Number of EV arrivals",
    toolTipTitle: "Daily traffic",
  };

  const options: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Hour",
        },
        ticks: {
          autoSkip: true,
          callback(tickValue: any) {
            if (tickValue % 2 === 0) {
              return this.getLabelForValue(tickValue);
            } else {
              return "";
            }
          },
        },
      },
      y: {
        title: {
          display: true,
          text: chartProperties.yAxisTitle,
        },
        min: 0,
        ticks: {
          display: true,
        },
        stacked: true,
      },
    },
  };

  let chartData: ChartData<
    "line",
    (number | ScatterDataPoint | BubbleDataPoint | null)[],
    unknown
  > = {
    labels: [
      "12am",
      "1am",
      "2am",
      "3am",
      "4am",
      "5am",
      "6am",
      "7am",
      "8am",
      "9am",
      "10am",
      "11am",
      "12pm",
      "1pm",
      "2pm",
      "3pm",
      "4pm",
      "5pm",
      "6pm",
      "7pm",
      "8pm",
      "9pm",
      "10pm",
      "11pm",
      "12am",
    ],
    datasets: [],
  };

  const publicCarArrivals = carHourlyArrivalsData.map(
    ({ hour, public_arrivals }) => ({
      x: hour,
      y: parseFloat(public_arrivals.toFixed(2)),
    })
  );

  const subscriptionCarArrivals = carHourlyArrivalsData.map(
    ({ hour, subscription_arrivals }) => ({
      x: hour,
      y: parseFloat(subscription_arrivals.toFixed(2)),
    })
  );

  const publicLightDutyArrivals = lightDutyHourlyArrivalsData.map(
    ({ hour, public_arrivals }) => ({
      x: hour,
      y: parseFloat(public_arrivals.toFixed(2)),
    })
  );

  const subscriptionLightDutyArrivals = lightDutyHourlyArrivalsData.map(
    ({ hour, subscription_arrivals }) => ({
      x: hour,
      y: parseFloat(subscription_arrivals.toFixed(2)),
    })
  );

  const publicTruckArrivals = truckHourlyArrivalsData.map(
    ({ hour, public_arrivals }) => ({
      x: hour,
      y: parseFloat(public_arrivals.toFixed(2)),
    })
  );

  const subscriptionTruckArrivals = truckHourlyArrivalsData.map(
    ({ hour, subscription_arrivals }) => ({
      x: hour,
      y: parseFloat(subscription_arrivals.toFixed(2)),
    })
  );

  const publicTrailerArrivals = trailerHourlyArrivalsData.map(
    ({ hour, public_arrivals }) => ({
      x: hour,
      y: parseFloat(public_arrivals.toFixed(2)),
    })
  );

  const subscriptionTrailerArrivals = trailerHourlyArrivalsData.map(
    ({ hour, subscription_arrivals }) => ({
      x: hour,
      y: parseFloat(subscription_arrivals.toFixed(2)),
    })
  );

  // Append the first data point to the end for "12am"
  publicCarArrivals.push(publicCarArrivals[0]);
  subscriptionCarArrivals.push(subscriptionCarArrivals[0]);
  publicLightDutyArrivals.push(publicLightDutyArrivals[0]);
  subscriptionLightDutyArrivals.push(subscriptionLightDutyArrivals[0]);
  publicTruckArrivals.push(publicTruckArrivals[0]);
  subscriptionTruckArrivals.push(subscriptionTruckArrivals[0]);
  publicTrailerArrivals.push(publicTrailerArrivals[0]);
  subscriptionTrailerArrivals.push(subscriptionTrailerArrivals[0]);

  const datasets: Dataset[] = [
    {
      label: `Cars (Public)`,
      data: publicCarArrivals,
      borderColor: "#f09059",
      backgroundColor: "#f5d3bf",
    },
    {
      label: `Cars (Private)`,
      data: subscriptionCarArrivals,
      borderColor: "#1cc1fc",
      backgroundColor: "#c3ebfa",
    },
    {
      label: `Light Trucks (Public)`,
      data: publicLightDutyArrivals,
      borderColor: "#3efaa9",
      backgroundColor: "#bff5dd",
    },
    {
      label: `Light Trucks (Private)`,
      data: subscriptionLightDutyArrivals,
      borderColor: "#913ef7",
      backgroundColor: "#d6b6fc",
    },
    {
      label: `Straight Trucks (Public)`,
      data: publicTruckArrivals,
      borderColor: Colors[0],
      backgroundColor: `rgba(${ColorsRGB[0]}, 0.2)`,
    },
    {
      label: `Straight Trucks (Private)`,
      data: subscriptionTruckArrivals,
      borderColor: Colors[1],
      backgroundColor: `rgba(${ColorsRGB[1]}, 0.2)`,
    },
    {
      label: `Tractor-Trailers (Public)`,
      data: publicTrailerArrivals,
      borderColor: Colors[2],
      backgroundColor: `rgba(${ColorsRGB[2]}, 0.2)`,
    },
    {
      label: `Tractor-Trailers (Private)`,
      data: subscriptionTrailerArrivals,
      borderColor: Colors[3],
      backgroundColor: `rgba(${ColorsRGB[3]}, 0.2)`,
    },
  ];

  let isFirstColor = true;

  datasets.forEach((dataset, index) => {
    if (dataset.data[0]) {
      dataset.fill = isFirstColor ? true : index - 1;
      isFirstColor = false;
    } else {
      dataset.fill = false;
    }
    chartData.datasets.push(dataset);
  });

  return <Chart type={"line"} options={options} data={chartData} />;
};

export default TrafficProfileChart;
