export const Colors = [
  "#05C2CC",
  "#FDBE02",
  "#7DC422",
  "#DB3AE8",
  "#128B2A",
  "#D3881C",

  // new colors for map
  "#004847",
  "#006262",
  "#017A7C",
  "#62ABAB",
  "#AAD0CF",
  "#0000bb",
];

export const ColorsRGB = [
  [5, 194, 204],
  [253, 190, 2],
  [125, 196, 34],
  [219, 58, 232],
  [18, 139, 42],
  [211, 136, 28],
  [0, 72, 71],
  [0, 98, 98],
  [1, 122, 124],
  [98, 171, 171],
  [170, 208, 207],
  [0, 0, 187],
];
