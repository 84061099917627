import { useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  InputAdornment,
  TextField,
  Checkbox,
  Stack,
  Divider,
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { useDebouncedEffect } from "dashboard/useDebouncedEffect";
import TimeSlider from "components/TimeSlider";
import { HubScenarioControls } from "types/hub-scenario-parameters";
import { SiteVehicleList } from "types/hub-site-vehicle";
import SiteSpecificSlider from "./SiteSpecificSlider";
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import { fleetsDataType } from "api/hub/fleetData";
import UtilitySelectBox from "./UtilitySelectBox";
import { HubUtilityRate } from "types/hub-utility-rate";

type AdvancedSettingsDialogProps = {
  openAdvancedSettingsDialog: boolean;
  annualAveragePerCharger: number;
  toggleAdvancedSettingsModal: () => void;
  setAnnualAveragePerCharger: (value: number) => void;
  scenarioControls: HubScenarioControls;
  setScenarioControls: (value: HubScenarioControls) => void;
  siteVehicles: SiteVehicleList;
  ldvOptimalStatus: boolean;
  optimalStatus: boolean;
  fleetsData: fleetsDataType;
  onClickLDVOptimal: () => void;
  onClickOptimal: () => void;
  utilityRates: HubUtilityRate[];
};
export default function AdvancedSettingsDialog({
  openAdvancedSettingsDialog,
  annualAveragePerCharger,
  toggleAdvancedSettingsModal,
  setAnnualAveragePerCharger,
  scenarioControls,
  setScenarioControls,
  siteVehicles,
  ldvOptimalStatus,
  optimalStatus,
  fleetsData,
  onClickLDVOptimal,
  onClickOptimal,
  utilityRates,
}: AdvancedSettingsDialogProps) {
  const [annualAverage, setAnnualAverage] = useState<number>(
    annualAveragePerCharger
  );

  useDebouncedEffect(
    () => setAnnualAveragePerCharger(annualAverage),
    [annualAverage],
    500
  );

  return (
    <Dialog
      open={openAdvancedSettingsDialog}
      onClose={() => toggleAdvancedSettingsModal()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={false}
    >
      <IconButton
        aria-label="close"
        onClick={() => toggleAdvancedSettingsModal()}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <GridCloseIcon />
      </IconButton>
      <DialogContent sx={{ padding: 5, paddingBottom: 3 }}>
        <Box>
          <Typography
            sx={{ flex: "1 1 100%", marginBottom: 3 }}
            variant="h2"
            id="tableTitle"
            component="div"
          >
            Advanced Settings
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: 2,
            }}
          >
            <Typography
              sx={{ marginRight: 3 }}
              variant="body1"
              id="tableTitle"
              component="span"
            >
              Average Annual Maintenance Cost Per Charger
            </Typography>
            <TextField
              sx={{ m: 1, width: "130px" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              type="number"
              value={annualAverage}
              inputProps={{ min: 0 }}
              onChange={(e) => setAnnualAverage(Number(e.target.value))}
            />
          </Box>
          <Stack direction="row" sx={{ marginTop: 1 }}>
            <Typography
              sx={{ marginTop: 2, marginRight: 2 }}
              variant="body1"
              id="tableTitle"
              component="span"
            >
              Utility Rate Structure
            </Typography>
            <UtilitySelectBox
              utilityRates={utilityRates}
              selectedUtilityRateId={scenarioControls.utilityRateId}
              setSelectedUtilityRateId={(utilityRateId: number) =>
                setScenarioControls({
                  ...scenarioControls,
                  utilityRateId,
                })
              }
            />
          </Stack>
          <Divider sx={{ marginTop: 1 }} />
          {(siteVehicles.cars || siteVehicles.lightDutyVehicle) && (
            <Typography variant="h6" sx={{ marginTop: 1 }}>
              LDV Settings
            </Typography>
          )}

          {siteVehicles.cars && (
            <Box>
              <Typography
                sx={{ marginRight: 3 }}
                variant="body1"
                id="tableTitle"
                component="span"
              >
                Car Operating Hours
              </Typography>

              <TimeSlider
                duplicateMidnight
                value={scenarioControls.carOperatingHours}
                onChange={(e, value) => {
                  setScenarioControls({
                    ...scenarioControls,
                    carOperatingHours: value as number[],
                  });
                }}
                valueLabelDisplay="auto"
                step={1}
              />
            </Box>
          )}
          {siteVehicles.lightDutyVehicle && (
            <Box sx={{ marginTop: 1 }}>
              <Typography
                sx={{ marginRight: 3 }}
                variant="body1"
                id="tableTitle"
                component="span"
              >
                Light Truck Operating Hours
              </Typography>
              <TimeSlider
                duplicateMidnight
                value={scenarioControls.lightDutyOperatingHours}
                onChange={(e, value) =>
                  setScenarioControls({
                    ...scenarioControls,
                    lightDutyOperatingHours: value as number[],
                  })
                }
                valueLabelDisplay="auto"
                step={1}
              />
            </Box>
          )}
          {(siteVehicles.cars || siteVehicles.lightDutyVehicle) && (
            <Stack spacing={3} sx={{ marginTop: 1 }}>
              <Stack sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                <SiteSpecificSlider
                  max={100}
                  min={0}
                  step={10}
                  defaultValue={scenarioControls.carsParkingPct * 100}
                  InputLabels={{
                    left: "Car",
                    right: "Light Truck",
                  }}
                  onChange={(value: number[]) =>
                    setScenarioControls({
                      ...scenarioControls,
                      carsParkingPct: value[0] / 100,
                    })
                  }
                  disabled={ldvOptimalStatus}
                />
                <Stack sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="subtitle2">Optimal Mix</Typography>
                  <Checkbox
                    checked={ldvOptimalStatus}
                    onClick={onClickLDVOptimal}
                    icon={<RadioButtonUnchecked />}
                    checkedIcon={<RadioButtonChecked />}
                  />
                </Stack>
              </Stack>
              <Stack spacing={1}>
                <SiteSpecificSlider
                  max={100}
                  min={0}
                  step={10}
                  InputLabels={{
                    left: "Public Access",
                    right: "Private",
                  }}
                  defaultValue={scenarioControls.publicCarChargerPct * 100}
                  onChange={(value: number[]) =>
                    setScenarioControls({
                      ...scenarioControls,
                      publicCarChargerPct: value[0] / 100,
                    })
                  }
                  disabled={Object.keys(fleetsData).length > 0}
                />
              </Stack>
            </Stack>
          )}

          {(siteVehicles.cars || siteVehicles.lightDutyVehicle) && (
            <Divider sx={{ marginTop: 2 }} />
          )}
          {(siteVehicles.trucks || siteVehicles.trailers) && (
            <Typography variant="h6" sx={{ marginTop: 2 }}>
              MHD Settings
            </Typography>
          )}
          {siteVehicles.trucks && (
            <Box sx={{ marginTop: 1 }}>
              <Typography
                sx={{ marginRight: 3 }}
                variant="body1"
                id="tableTitle"
                component="span"
              >
                Truck Operating Hours
              </Typography>
              <TimeSlider
                duplicateMidnight
                value={scenarioControls.truckOperatingHours}
                onChange={(e, value) =>
                  setScenarioControls({
                    ...scenarioControls,
                    truckOperatingHours: value as number[],
                  })
                }
                valueLabelDisplay="auto"
                step={1}
              />
            </Box>
          )}
          {siteVehicles.trailers && (
            <Box sx={{ marginTop: 1 }}>
              <Typography
                sx={{ marginRight: 3 }}
                variant="body1"
                id="tableTitle"
                component="span"
              >
                Tractor-Trailer Operating Hours
              </Typography>
              <TimeSlider
                duplicateMidnight
                value={scenarioControls.trailerOperatingHours}
                onChange={(e, value) =>
                  setScenarioControls({
                    ...scenarioControls,
                    trailerOperatingHours: value as number[],
                  })
                }
                valueLabelDisplay="auto"
                step={1}
              />
            </Box>
          )}

          <>
            {(siteVehicles.trucks || siteVehicles.trailers) && (
              <Stack spacing={3} sx={{ marginTop: 2 }}>
                <Stack sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                  <SiteSpecificSlider
                    max={100}
                    min={0}
                    step={10}
                    defaultValue={scenarioControls.trucksParkingPct * 100}
                    InputLabels={{
                      left: "Straight Truck",
                      right: "Tractor-Trailer",
                    }}
                    onChange={(value: number[]) =>
                      setScenarioControls({
                        ...scenarioControls,
                        trucksParkingPct: value[0] / 100,
                      })
                    }
                    disabled={optimalStatus}
                  />
                  <Stack sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="subtitle2">Optimal Mix</Typography>
                    <Checkbox
                      checked={optimalStatus}
                      onClick={onClickOptimal}
                      icon={<RadioButtonUnchecked />}
                      checkedIcon={<RadioButtonChecked />}
                    />
                  </Stack>
                </Stack>
                <Stack spacing={1}>
                  <SiteSpecificSlider
                    max={100}
                    min={0}
                    step={10}
                    InputLabels={{
                      left: "Public Access",
                      right: "Private",
                    }}
                    defaultValue={scenarioControls.publicChargerPct * 100}
                    onChange={(value: number[]) =>
                      setScenarioControls({
                        ...scenarioControls,
                        publicChargerPct: value[0] / 100,
                      })
                    }
                    disabled={Object.keys(fleetsData).length > 0}
                  />
                </Stack>
              </Stack>
            )}
          </>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
