import { Box, Grid } from "@mui/material";
import { useMemo } from "react";
import { HubUtilityRate } from "types/hub-utility-rate";
import { ScenarioYearlyParam, SegmentData } from "types/hub-scenario-data";
import ParametersBox from "./components/ParametersBox";
import VehicleClassBox from "./components/VehicleClassBox";
import { SiteVehicleList } from "types/hub-site-vehicle";

type ParametersHubProp = {
  siteName: string | undefined;
  scenarioName: string | undefined;
  utilityRate: HubUtilityRate | null | undefined;
  carData: SegmentData | undefined;
  LightDutyData: SegmentData | undefined;
  truckData: SegmentData | undefined;
  trailerData: SegmentData | undefined;
  carYearlyParams: ScenarioYearlyParam[];
  lightDutyYearlyParams: ScenarioYearlyParam[];
  truckYearlyParams: ScenarioYearlyParam[];
  trailerYearlyParams: ScenarioYearlyParam[];
  siteVehicles: SiteVehicleList;
};

export const ParametersHub = ({
  siteName,
  scenarioName,
  utilityRate,
  carData,
  LightDutyData,
  truckData,
  trailerData,
  carYearlyParams,
  lightDutyYearlyParams,
  truckYearlyParams,
  trailerYearlyParams,
  siteVehicles,
}: ParametersHubProp) => {
  const vehiclesData = useMemo(() => {
    const vehicles = [
      {
        enabled: siteVehicles.cars,
        vehicleClassId: carData?.vehicle_type_id,
        vehicleClass: carData?.vehicle_type,
        vehicleClassDescription: carData?.vehicle_type_description,
        yearlyParams: carYearlyParams,
      },
      {
        enabled: siteVehicles.lightDutyVehicle,
        vehicleClassId: LightDutyData?.vehicle_type_id,
        vehicleClass: LightDutyData?.vehicle_type,
        vehicleClassDescription: LightDutyData?.vehicle_type_description,
        yearlyParams: lightDutyYearlyParams,
      },
      {
        enabled: siteVehicles.trucks,
        vehicleClassId: truckData?.vehicle_type_id,
        vehicleClass: truckData?.vehicle_type,
        vehicleClassDescription: truckData?.vehicle_type_description,
        yearlyParams: truckYearlyParams,
      },
      {
        enabled: siteVehicles.trailers,
        vehicleClassId: trailerData?.vehicle_type_id,
        vehicleClass: trailerData?.vehicle_type,
        vehicleClassDescription: trailerData?.vehicle_type_description,
        yearlyParams: trailerYearlyParams,
      },
    ];
    return vehicles.filter((vehicle) => vehicle.enabled);
  }, [
    siteVehicles,
    carData,
    carYearlyParams,
    LightDutyData,
    lightDutyYearlyParams,
    truckData,
    trailerData,
    truckYearlyParams,
    trailerYearlyParams,
  ]);

  return (
    <Box sx={{ padding: 2 }}>
      <ParametersBox
        siteName={siteName}
        scenarioName={scenarioName}
        utilityRate={utilityRate}
        truckData={truckData}
        trailerData={trailerData}
        truckYearlyParams={truckYearlyParams}
        trailerYearlyParams={trailerYearlyParams}
        carData={carData}
        LightDutyData={LightDutyData}
        carYearlyParams={carYearlyParams}
        lightDutyYearlyParams={lightDutyYearlyParams}
        siteVehicles={siteVehicles}
      />
      <Grid container rowSpacing={2} columnSpacing={2}>
        {vehiclesData.map((vehicleData, index) => (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={6} key={index}>
            <VehicleClassBox
              vehicleClass={vehicleData.vehicleClass}
              vehicleClassDescription={vehicleData.vehicleClassDescription}
              yearlyParams={vehicleData.yearlyParams}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ParametersHub;
